.container {
  padding-right: 20px;
  padding-left: 20px;
}
.navbar-custom .navbar-toggle {
  margin-right: 5px;
}
nav[data-nav] {
  background-color: #22252c;
}
nav[data-nav] .navbar-brand {
  height: 50px;
  width: 76px;
}
@media (min-width: 768px) {
  nav[data-nav] .navbar-brand {
    margin-left: 121px!important;
  }
}
@media (max-width: 768px) {
  header {
    margin-top: 74px;
  }
  .navbar-custom .nav li a {
      display: inline-block;
  }
  nav {
    background-color: #22252c!important;
  }
}
nav[data-nav] .img-placeholder {
  display: none;
} 
nav[data-nav] .nav {
  padding-top: 0;
}

nav[data-page="home"] .home:before,
nav[data-page="services"] .services:before,
nav[data-page="about"] .about:before,
nav[data-page="history"] .history:before,
nav[data-page="projects"] .projects:before,
nav[data-page="cabling"] .cabling:before,
nav[data-page="contact"] .contact:before {
    content: "\a0";
    display: block;
    padding: 1px 0;
    border-radius: 2px;
    line-height: 1px;
    background-color: #ff9b00;
    position: absolute;
    bottom: 0;
    width: 100%;
}