.client-wrap {
    max-width: 500px;
    margin: 0 auto;
}
.client-1, .client-2, .client-3 {
    display: inline-block;
    height: 70px !important;
    width: 50%;
}
@media (max-width: 500px) {
    .client-1, .client-2, .client-3 {
        width: 100%;
    }
}